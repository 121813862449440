<template>
  <div class="roadmap">
    <div class="layout" id="roadmap">
      <roadmapitem
        :nth="'odd'"
        :showColor="'#7AE2E9'"
        :month="'AUG'"
        :year="2022"
        :title="'Go Go Racing'"
        :text="'TBA'"
      ></roadmapitem>
      <roadmapitem
        :nth="'even'"
        :showColor="'#39C2ED'"
        :month="'oct'"
        :year="2022"
        :title="'Bacca The Rat'"
        :text="'TBA'"
      ></roadmapitem>
      <roadmapitem
        :nth="'odd'"
        :showColor="'#3A76EA'"
        :month="'DEC'"
        :year="2022"
        :title="'Poker Bull'"
        :text="'TBA'"
      ></roadmapitem>
      <roadmapitem
        :nth="'even'"
        :showColor="'#683AEA'"
        :month="'feb'"
        :year="2023"
        :title="'Two Eight Frenzy'"
        :text="'TBA'"
      ></roadmapitem>
      <roadmapitem
        :nth="'odd'"
        :showColor="'#9B157F'"
        :month="'apr'"
        :year="2023"
        :title="'Lucky Roulette'"
        :text="'TBA'"
      ></roadmapitem>
      <roadmapitem
        :nth="'even'"
        :showColor="'#CE202D'"
        :month="'may'"
        :year="2023"
        :title="'Ocean World'"
        :text="'TBA'"
      ></roadmapitem>
    </div>
    <div class="layout">
      <div class="finish">
        <p>Games<br />good games<br />fun games</p>
        <p>our goal is to add more games</p>
      </div>
    </div>
  </div>

  <pagebottom></pagebottom>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
// import { useStore } from "vuex";
import pagebottom from "../components/pagebottom/pagebottom.vue";
import roadmapitem from "../components/roadmapItem/roadmapItem.vue";

export default defineComponent({
  name: "roadmap",
  components: { pagebottom, roadmapitem },
  setup() {
    // let store = useStore();
    // let iframeSerc = ref("");
    onMounted(() => {});
    return {};
  },
});
</script>
<style lang="scss" scoped src="./roadmap.scss"></style>
