<template>
  <div :class="['item', nth]">
    <div class="date mortina">
      <div class="month" :style="{ color: showColor }">{{ month }}</div>
      <div class="year" :style="{ color: showColor }">{{ year }}</div>
    </div>
    <div class="path">
      <div class="line" :style="{ background: showColor }"></div>
      <div class="dot dot_start">
        <svg
          width="26"
          height="32"
          viewBox="0 0 26 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.9202 12.9427C25.9202 20.0907 13.0002 31.976 13.0002 31.976C13.0002 31.976 0.0802002 20.0907 0.0802002 12.9427C0.0802002 5.79463 5.86468 0 13.0002 0C20.1357 0 25.9202 5.79463 25.9202 12.9427Z"
            fill="#7AE2E9"
            :style="{ fill: showColor }"
          />
          <path
            d="M9.19995 9.13599L17.6563 14.0268L9.19995 28.1693L3.11995 20.556L9.19995 9.13599Z"
            fill="black"
            fill-opacity="0.15"
          />
          <ellipse
            cx="13.0001"
            cy="12.9426"
            rx="5.32"
            ry="5.32933"
            fill="white"
          />
        </svg>
      </div>
      <div class="dot dot_end">
        <svg
          width="38"
          height="48"
          viewBox="0 0 38 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M38 28.5448C38 17.9929 19 0.447968 19 0.447968C19 0.447968 0 17.9929 0 28.5448C0 39.0967 8.50659 47.6506 19 47.6506C29.4934 47.6506 38 39.0967 38 28.5448Z"
            fill="#7AE2E9"
            :style="{ fill: showColor }"
          />
        </svg>
      </div>
    </div>
    <div class="text">
      <div class="date mortina onlymobile">
        <div class="month" :style="{ color: showColor }">{{ month }}</div>
        <div class="year" :style="{ color: showColor }">{{ year }}</div>
      </div>
      <h2 class="mortina" :style="{ color: showColor }">{{ title }}</h2>
      <p v-html="text"></p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.item {
  color: #d4d3db;
  width: 100%;
  height: 2.3rem;
  display: flex;
  justify-content: center;
  h2 {
    margin-top: 0;
    margin-bottom: 0.15rem;
    font-size: 0.24rem;
  }
  .path {
    width: 0.38rem;
    margin: 0 0.18rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .line {
      position: absolute;
      height: 100%;
      top: -0.3rem;
      width: 0.08rem;
    }

    .dot {
      position: absolute;
      display: flex;
      align-items: flex-end;
    }

    .dot_start {
      width: 0.26rem;
      height: 0.32rem;
      top: -0.32rem;
    }

    .dot_end {
      width: 0.38rem;
      height: 0.48rem;
      bottom: 0;
    }
  }

  .date,
  .text {
    width: 4.5rem;
    position: relative;
    top: -0.6rem;
  }

  .date {
    .month {
      font-size: 0.42rem;
    }

    .year {
      font-size: 0.32rem;
    }
  }

  .text {
    p {
      font-size: 0.18rem;
      line-height: 0.25rem;
      color: #d4d3db;
    }
  }
  &.odd {
    flex-direction: row;
    .date {
      text-align: right;
    }
    .text {
      text-align: left;
    }
  }
  &.even {
    flex-direction: row-reverse;
    .date {
      text-align: left;
    }
    .text {
      text-align: right;
    }
  }
}
</style>
<script>
import { defineComponent } from "vue";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "",
  props: ["nth", "showColor", "month", "year", "title", "text"],
  components: {},
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    return {};
  },
});
</script>
